class API {
    static URL = 'https://ohai.jingyaedu.cn/';

    static login(login) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'ohai-backend/login?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(login),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static getLatestContest(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'ohai-backend/contest/getLatestContest?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static getChoiceTopicList(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'ohai-backend/topic/getChoiceTopicList?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static checkUserStatus(code, promoterId, contestId) {
        return fetch(API.URL + 'ohai-backend/user/checkUserStatus', {
            method: 'POST',
            body: JSON.stringify({ code: code, promoterId: promoterId, contestId: contestId }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static fillUserInfo(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'ohai-backend/user/fillUserInfo?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static getUserInfo(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'ohai-backend/user/getUserInfo?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static submitSolution(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'ohai-backend/contest/submitSolution?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static getPrize(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'ohai-backend/contest/getPrize?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static setUserStreet(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'ohai-backend/user/setUserStreet?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }

    static updateAccessTime(options) {
        const token = sessionStorage.getItem('token');
        const refresh_token = 0;
        return fetch(API.URL + 'ohai-backend/user/updateAccessTime?refresh_token=' + refresh_token, {
            method: 'POST',
            body: JSON.stringify(options),
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        }).then(async (response) => {
            return await response.json();
        })
    }
}

export default API