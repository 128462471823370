import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import QRCode from 'qrcode.react';

const PromoteInfo = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (location.state) {
            const { promoterId } = location.state
            setUrl(`http://ohai.jingyaedu.cn/?promoterId=${promoterId}`)
        } else {
            navigate('/login')
        }
    }, [])

    useEffect(() => {
    }, [url])

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#ffffff', display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', alignContent: 'center' }}>
            <div style={{ textAlign: 'center' }}>
                <QRCode value={url} renderAs='canvas' />
            </div>
        </div>
    )
}

export default PromoteInfo;