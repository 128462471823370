import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        let params = new URLSearchParams(window.location.search)//获取code、promoterId
        let origin = window.location.origin
        if (!origin) {
            origin = "https://ohai.jingyaedu.cn"
        }
        let promoterId = params.get("promoterId")

        let contestId = sessionStorage.getItem("contestId") ? sessionStorage.getItem('contestId') : params.get("contestId")
        if (!contestId) {
            contestId = 1;
        }
        sessionStorage.setItem('contestId', contestId)
        if (contestId != 1) {
            document.title = '瓯海公安反诈大闯关'
            navigate('/fanzhaDetail')
        } else {
            document.title = '安全感认可度测评'
            navigate('/activityDetail')
        }
    }, [])
    return;
}


export default Home;