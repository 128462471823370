import React, { useState, useEffect } from "react";
import { Button, message } from 'antd';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../data/api";
import decodeJwt from 'jwt-decode';
import '../css/Activity.css';
import TextArea from "antd/lib/input/TextArea";

const Activity = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo, contestId } = location.state;
    const [latestContest, setLatestContest] = useState(null);

    const getLatestContest = () => {
        API.getLatestContest({ id: contestId }).then(
            data => {
                setLatestContest(data[0])
                sessionStorage.setItem("contestInfo", data[0])
                sessionStorage.setItem("contestId", data[0].contest_id)
            }
        ).catch(err => { return err })
    }

    useEffect(() => {
        getLatestContest();
    }, [])

    useEffect(() => {
    }, [userInfo, latestContest])

    //跳转答题
    const onStartAnswer = () => {
        if (userInfo && latestContest) {
            navigate('/answer', { state: { contestId: latestContest?.contest_id, userInfo: userInfo } })
        }
    }

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#750df1', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {contestId == 2 ?
                <div className="fanzhaTop"></div> :
                <div className="top"></div>
            }
            <div className="content" >
                <div style={{ color: '#3c009c', textAlign: 'center', margin: 'auto', fontSize: '16px', background: '#ffffff', width: '200px' }}>
                    活动简介
                </div>
                <div className="activityContent">
                    <div style={{ width: '100%', height: '100%' }}>
                        <TextArea style={{ fontSize: '18px', height: '100%', color: '#fff' }} disabled={true} bordered={false} rows={6} value={latestContest?.introduction} />
                    </div>
                </div>
            </div>
            <div style={{ textAlign: 'center', width: '100%' }}>
                <button
                    className="btn"
                    onClick={() => onStartAnswer()}
                >
                    立即参赛
                </button>
            </div>
        </div >
    )
}

export default Activity;