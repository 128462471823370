import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Select, Input, message, InputNumber } from 'antd';
import { streetList } from "../data/street";
import API from "../data/api";
import '../css/Activity.css';


const Information = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo } = location.state;

    const onFinish = (values) => {
        values.openid = userInfo.openid
        values.name = values.name.trim();
        values.phone = values.phone.toString().trim();
        values.address = values.address.trim();
        if (!(/^1\d{10}$/.test(values.phone))) {
            return message.warning({
                content: '手机号格式不正确，请重新输入',
                style: {
                    marginTop: '50vh'
                }
            })
        } else {
            API.fillUserInfo(values).then(
                data => {
                    if (data[0].address != '') {
                        navigate('/activity', { state: { userInfo: data[0] } })
                    } else {
                        message.warning({
                            content: '请输入您的信息',
                            style: {
                                marginTop: '50vh'
                            }
                        })
                    }
                }
            ).catch(err => { return err })
        }
    }

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#750df1', display: 'flex', flexDirection: 'column' }}>
            <div className="top">
            </div>
            <div style={{ background: '#3c009c', borderRadius: '10px', margin: '0 auto', width: '90%' }}>
                <div style={{ color: '#3c009c', textAlign: 'center', margin: 'auto', fontSize: '18px', background: '#ffffff', width: '200px' }}>
                    填写信息
                </div>
                <div style={{ padding: '20px 10px 0 10px' }}>
                    <Form
                        size="large"
                        layout={'horizontal'}
                        onFinish={onFinish}
                    >
                        <Form.Item name='name'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入你的姓名',
                                },
                            ]}
                        >
                            <Input placeholder="请输入你的姓名" />
                        </Form.Item>
                        <Form.Item name='phone'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入你的手机号码',
                                },
                            ]}
                        >
                            <InputNumber type={'tel'} style={{ width: '100%' }} placeholder="请输入你的手机号码" pattern="\d*" />
                        </Form.Item>
                        <Form.Item name='street_id'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择街道',
                                },
                            ]}
                        >
                            <Select placeholder='请选择街道'
                                options={streetList.map(item => ({
                                    label: item.label,
                                    value: item.value,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item name='address'
                            rules={[
                                {
                                    required: true,
                                    message: '请填写奖品接收地址',
                                },
                            ]}
                        >
                            <Input placeholder='请填写奖品接收地址' />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                offset: 5,
                                span: 16,
                            }}
                        >
                            <button
                                className="btn"
                                htmltype="submit">立即参赛</button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Information;